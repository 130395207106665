import Link from 'next/link';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
const VideoSchema = dynamic(() => import('./HomePageVideoSchema'));

interface HomepageVideoProps {
  desktopVideoUrl: string;
  mobileVideoUrl: string;
  videoAltText: string;
  href: string;
}

function HomepageVideo({ desktopVideoUrl, mobileVideoUrl, videoAltText, href }: HomepageVideoProps) {
  const desktopThumbnailUrl = `${desktopVideoUrl.replace('https://cdn.dresden.vision/video/home-video', 'https://w.dresden.vision/img/video/home-video')}.webp?q=60`;
  const mobileThumbnailUrl = `${mobileVideoUrl.replace('https://cdn.dresden.vision/video/home-video', 'https://w.dresden.vision/img/video/home-video')}.webp?q=40`;
  const router = useRouter();

  return (
    // <>
    //   {router.locale == 'ca' ? (
    //     <Link href={href} className="block cursor-pointer">
    //       <VideoSchema
    //         videoAltText={videoAltText}
    //         desktopThumbnailUrl={desktopThumbnailUrl}
    //         mobileThumbnailUrl={mobileThumbnailUrl}
    //         desktopVideoUrl={desktopVideoUrl}
    //       />
    //       <div
    //         className="relative w-full pt-[124.80%]  md:hidden" // 16:9 aspect ratio
    //       >
    //         <video
    //           playsInline
    //           autoPlay
    //           muted
    //           loop
    //           poster={mobileThumbnailUrl}
    //           className="absolute top-0 left-0 w-full h-full object-cover"
    //         >
    //           <source src={mobileVideoUrl} type="video/mp4" />
    //           <meta itemProp="description" content={videoAltText} />
    //         </video></div>
    //       <div
    //         className="relative w-full pt-[40.00%]  hidden md:block" // 16:9 aspect ratio
    //       >
    //         <video
    //           playsInline
    //           autoPlay
    //           muted
    //           loop
    //           poster={desktopThumbnailUrl}
    //           className="absolute top-0 left-0 w-full h-full object-cover"
    //         >
    //           <source src={desktopVideoUrl} type="video/mp4" />
    //           <meta itemProp="description" content={videoAltText} />

    //         </video>
    //       </div>
    //     </Link>
    //   ) : (
    <Link href="/page/50-percent-off-sunglasses" className="block cursor-pointer">
      {/* Mobile Image */}
      <div className="relative w-full pt-[120%] md:hidden">
        <Image
          src="https://cdn.dresden.vision/images/valentines-homepage-mobile.jpg"
          alt={videoAltText}
          fill
          className="object-cover"
          priority
        />
      </div>
      {/* Desktop Image */}
      <div className="relative w-full pt-[35.00%] hidden md:block">
        <Image
          src="https://cdn.dresden.vision/images/valentines-homepage-desktop.jpg"
          alt={videoAltText}
          fill
          className="object-cover"
          priority
        />
      </div>
    </Link>
    //   )}
    // </>
  );
}

export default HomepageVideo;